@import "~@verified/veform/themes/default/style.scss";

/* cyrillic-ext */
@font-face {
	font-family: 'PT Serif';
	font-style: normal;
	font-weight: 400;
	src: local('PT Serif'), local('PTSerif-Regular'), url(https://fonts.gstatic.com/s/ptserif/v11/EJRVQgYoZZY2vCFuvAFbzr-_dSb_nco.woff2) format('woff2');
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
	font-family: 'PT Serif';
	font-style: normal;
	font-weight: 400;
	src: local('PT Serif'), local('PTSerif-Regular'), url(https://fonts.gstatic.com/s/ptserif/v11/EJRVQgYoZZY2vCFuvAFSzr-_dSb_nco.woff2) format('woff2');
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* latin-ext */
  @font-face {
	font-family: 'PT Serif';
	font-style: normal;
	font-weight: 400;
	src: local('PT Serif'), local('PTSerif-Regular'), url(https://fonts.gstatic.com/s/ptserif/v11/EJRVQgYoZZY2vCFuvAFYzr-_dSb_nco.woff2) format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
	font-family: 'PT Serif';
	font-style: normal;
	font-weight: 400;
	src: local('PT Serif'), local('PTSerif-Regular'), url(https://fonts.gstatic.com/s/ptserif/v11/EJRVQgYoZZY2vCFuvAFWzr-_dSb_.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  
   @font-face {
	font-family:"brownregular";
	src: local('brownregular'), url(/fonts/lineto-brown-regular.woff2) format("woff2");
	font-weight:normal;
	font-style:normal;
   }

body{
	font-family: 'PT Serif','Times New Roman', Times, serif;
}
h1,h2,h3,h4,h5,h6{
	font-family: 'brownregular', Arial, Helvetica, sans-serif ;
	font-weight: bold;
}
.main {
	margin-top: 40px;
	max-width: 800px !important;
}

.btn-plus {
	background: #218BCB;
	display: inline-block;
	border-radius: 50%;
	color: #fff;
	padding: 6px;
	font-size: 26px;
	line-height: 15px;
	&:hover {
		cursor: pointer;
		background: #1c74aa;
	}
	margin: -5px 5px;
}

.btn-minus {
	background: #218BCB;
	display: inline-block;
	border-radius: 50%;
	color: #fff;
	padding: 6px 9px;
	font-size: 26px;
	line-height: 15px;
	&:hover {
		cursor: pointer;
		background: #1c74aa;
	}
	margin: -5px 5px;
}

.btn-minus-gray {
	background: #c1c1c1 !important;
	&:hover {
		cursor: not-allowed !important;
	}
}
.card{
	background: rgba(255, 255, 255, 0.88)
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle{
	background-color: #faa71d;
    border-color: #faa71d;
}
.btn-primary{
	background-color: #faa71d;
    border-color: #faa71d;
}
.btn-primary:hover{
	background-color: #efa52b;
    border-color: #efa52b;
}
.steps ul li.current span{
	color: #efa52b;
    border: 1px solid #faa71d;
}
.steps ul li.complete span{
	background-color: #efa52b;
    border-color: #efa52b;
}
.loader {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	pointer-events: none;
	z-index: 1;
  }
  .smooth,
  .smooth * {
	transition: all 0.5s ease;
  
	&.delay,
	& .delay {
	  transition-delay: 0.5s;
	}
  }
  .price{
	  color:#efa52b;
  }
  .hidden{
	  display: none;
  }
  .cover{
	      background-color: black;
  }