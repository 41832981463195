<template>
  <div id="cover">
    <div class="loader smooth" :style="{ opacity: loading ? 1 : 0 }">
      <img src="//app.verified.eu/lib/iframes/common/assets/loader64.gif" />
    </div>    
    <div class="container main" v-if="!loading && !sent">
      <ve-row>
        <ve-col>
          <!-- #region form1 -->
          <div class="card border-0 shadow-sm">
            <div class="card-body">
              <ve-row>
                <ve-col md12>
                  <img :src="'./logob.png'" style="margin-right: 10px;padding-bottom:5px;max-width: 30%;"/>
                </ve-col>

                <ve-col md12>
                  <ve-headline :headline="___('title')" size="2em"></ve-headline>
                </ve-col>
                <ve-col>
                </ve-col>
              </ve-row>
              <ve-custom-stepper :steps="steps()" :step="step" @step-click="previousStep" @btn-next-click="nextStep"></ve-custom-stepper>              
              <br><br>
              <ve-form v-show="step == 1" ref="form1" class="ml-3">
                <div>
                  <ve-custom-label :label="___('info')" />
                  <br>
                <ve-row>
                  <ve-col md12  xs12>
                    <ve-form-group>
                      <h6><ve-label :label="___('adults')" required/></h6>
                      <ve-custom-checkbox 
									             v-model="ud.isTraining"
									             :options="{ yes: { label: ___('isTraining') } }"
                               :data="{validation_error:'Test'}"></ve-custom-checkbox>
                     
                    </ve-form-group>
                  </ve-col>
                  <ve-col md12 xs12 >
                    <!--<ve-label 
                          :label="___('juniors')" 
                          :tip="{'text': 'juniors','text-placement': 'left' }"
                          />-->
                    <ve-form-group>
                       <ve-custom-radio
                      v-model="ud.juniorTimePeriod" 
                      :options="{
                        radioOp1: { label: ___('radioOp1') },
                        radioOp2: { label: ___('radioOp2') },
                        radioOp3: { label: ___('radioOp3') },
                        radioOp4: { label: ___('radioOp4'),tip:{'text': 'radioOp4Tip','text-placement': 'bottom','tip-position':'left'} },
                        radioOp5: { label: ___('radioOp5') }
                      }"
                      :inline="false"
                      :rules="radioRule()"
                      />
                    </ve-form-group>
                    <ve-input-field
                        md12 xs12
                        :label="___('rawmotionInfo')"
                        type="text"
                        v-model="ud.rawmotionInfo"
                      />
                  </ve-col> 
                  </ve-row>
                  <ve-row>   
                  <ve-col md12 xs12 >
                    <ve-form-group>
                      <!--<ve-label :label="___('juniors')" />-->
                       <!--<h6 required>{{___('juniors')}}</h6>-->
                     <h6>  <ve-label :label="___('juniors')" required/></h6>
                      <ve-label :label="___('juniorAge')" />
                      <ve-label :label="___('juniorInfo')" />
                       <ve-custom-radio 
                      v-model="ud.juniorTimePeriod"
                      :options="{
                        juniorRadOp1: { label: ___('juniorRadOp1') },
                        juniorRadOp2: { label: ___('juniorRadOp2') },
                        juniorRadOp3: { label: ___('juniorRadOp3') }
                      }"
                      :inline="false"
                      :rules="radioRule()"
                      />
                      
                    </ve-form-group>
                  </ve-col>                  
                </ve-row>
                 <ve-row>
                  <ve-col md12  xs12>
                    <ve-form-group>
                      <!--<h6 required>{{___('students')}}</h6>-->
                      <h6><ve-label :label="___('students')"/></h6>
                      <!--<ve-label :label="___('students')" />-->
                      
                      <ve-label :label="___('studentInfo')" />
                      <ve-label :label="___('studentHead')" />
                      <ve-custom-radio 
                      v-model="ud.juniorTimePeriod"
                      :options="{
                        studentRadOp1: { label: ___('studentRadOp1') },
                        studentRadOp2: { label: ___('studentRadOp2') }
                      }"
                      :inline="false"
                      :rules="radioRule()"
                      />
                      <ve-label :label="___('pensionerHead')" />
                      <ve-custom-radio 
                      v-model="ud.juniorTimePeriod"
                      :options="{
                        pensionerRadOp1: { label: ___('studentRadOp1') },
                        pensionerRadOp2: { label: ___('studentRadOp2') }
                      }"
                      :inline="false"
                      :rules="radioRule()"
                      />
                      <div v-bind:class="[radioValidate ? 'custom-invalid-feedback' : 'hidden']" class="">{{ __("validation.radio")}}</div>
                    </ve-form-group>
                  </ve-col>                  
                </ve-row>
                <ve-row class="row " >
                  <ve-col md12 xs12 v-html="___('contactInfo')">
                  </ve-col>
                   <ve-col md12 xs12 v-html="___('contactInfo2')">
                  </ve-col>
                </ve-row>
                </div>
              </ve-form>
              <ve-form v-show="step == 2" ref="form2" class="ml-3">
                <div>
                   <h5>{{___('enterInfo')}}</h5>
                   <h6>{{___('payerInfoHead')}}</h6>
                   <ve-row>
                      <ve-input-field
                        md4 xs12
                        :label="___('ssn')"
                        type="text"
                        mask="########-####" 
                        :rules="agecheck()"
                        v-model="ud.ssn"
                        required
                      />
                    <ve-input-field
                      md4 xs12
                      :label="___('firstName')"
                      type="text"
                      v-model="ud.firstName"
                      required
                      rules="required"
                    />
                    <ve-input-field
                      md4 xs12
                      :label="___('lastName')"
                      type="text"
                      v-model="ud.lastName"
                      required
                      rules="required"
                    />
                  </ve-row>
                   <ve-row>
                    <ve-input-field
                        md6 xs12
                        :label="___('email')"
                        type="email"
                        v-model="ud.email"
                        required
                        rules="required||email"
                      />
                     <ve-input-field
                      md6 xs12
                      :label="___('address')"
                      v-model="ud.address"
                      required
                      rules="required"
                    />
                  </ve-row>
                  <ve-row>
                    <ve-input-field
                      md6 xs12
                      :label="___('zip')"
                      v-model="ud.zip"
                      required
                      rules="required"
                    />
                    <ve-input-field
                      md6 xs12
                      :label="___('city')"
                      v-model="ud.city"
                      required
                      rules="required"
                    />
                    
                  </ve-row>
                  <ve-row>
                    <ve-col>
                      <ve-form-group>
                        <ve-label
                          :label="___('bank')"
                          required/>
                         <ve-select
                          :options="selectBanks"
                          :searchable="true"
                          v-model="ud.bank"
                          rules="required"
                        />
                      </ve-form-group>
                    </ve-col>
                  </ve-row>
                  <div v-if="ud.bank">
                    <ve-row>
                      <ve-input-field
                        md6 xs12
                        :label="___('clearingNo')"
                        type="text"
                        :mask="clearingNoMask" 
                        v-model="ud.clearingNo"
                        required
                        :rules="getClearingNoRule()"
                        :placeholder="banks[this.ud.bank].clearNoFormat"
                      />
                      <ve-input-field
                        md6 xs12
                        :label="___('accountNo')"
                        type="text"
                        v-model="ud.accountNo"
                        required
                        :rules="getAccountNoRule()"
                        :placeholder="banks[ud.bank].accNoFormat"
                      />
                    </ve-row>
                    <!--<ve-row>
                      <ve-input-field
                        md12 xs12
                        :label="___('accountHolderName')"
                        type="text"
                        v-model="ud.accountHolderName"
                        required
                        rules="required"
                      />
                    </ve-row>-->
                  </div>
                  <ve-row>
                    <ve-label
                      :label="___('disclaimerText')"
                    />
                  </ve-row>
                  <br>
                   <ve-row>
                    <ve-col md12  xs12>
                    <ve-form-group>
                      <!--<h6><ve-label :label="___('otherPayer')" required/></h6>-->
                      <ve-checkbox 
									             v-model="ud.otherPayer"
									             :options="{ otherPayer: { label: ___('otherPayer') } }"></ve-checkbox>
                     
                    </ve-form-group>
                  </ve-col>
                  </ve-row>
                  <div v-if="ud.otherPayer.otherPayer">
                     <h5>{{___('memberTask')}}</h5>
                    <ve-row>
                      <ve-input-field
                        md4 xs12
                        :label="___('ssn')"
                        type="text"
                        mask="########-####" 
                        rules="required|min:13"
                        v-model="ud.otherSsn"
                        required
                      />
                    <ve-input-field
                      md4 xs12
                      :label="___('firstName')"
                      type="text"
                      v-model="ud.otherFirstName"
                      required
                      rules="required"
                    />
                    <ve-input-field
                      md4 xs12
                      :label="___('lastName')"
                      type="text"
                      v-model="ud.otherLastName"
                      required
                      rules="required"
                    />
                  </ve-row>
                   <ve-row>
                    <ve-input-field
                        md6 xs12
                        :label="___('email')"
                        type="email"
                        v-model="ud.otherEmail"
                        required
                        rules="required||email"
                      />
                     <ve-input-field
                      md6 xs12
                      :label="___('address')"
                      v-model="ud.otherAddress"
                      required
                      rules="required"
                    />
                  </ve-row>
                  <ve-row>
                    <ve-input-field
                      md6 xs12
                      :label="___('zip')"
                      v-model="ud.otherZip"
                      required
                      rules="required"
                    />
                    <ve-input-field
                      md6 xs12
                      :label="___('city')"
                      v-model="ud.otherCity"
                      required
                      rules="required"
                    />
                    
                  </ve-row>
                    </div>
                  <br>
                  <ve-row>
                    <button @click="previousStep()" type="button" class="btn btn-rounded btn-primary next-btn" v-if="step==2">
                        <span >{{ ___('back') }}</span>
                    </button>
                    <ve-col>
                      <button class="btn btn-rounded btn-primary te" type="button" @click="submit()">{{___('forward')}}</button>
                    </ve-col>
                  </ve-row>
                </div>
              </ve-form>    
          
            </div>
          </div>
          <!-- #endregion -->
        </ve-col>
      </ve-row>
    </div>
  </div>
</template>

<script>
export { default } from "./script.js";
</script>
<style>
.inline-input{
  display: inline;
}

.inline-input > input{
  display: inline;
  width: 100px;
}
#cover{
  width: 100%;
  overflow: scroll;
  height: 100%;
  position: absolute;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
}
</style>